export const options=[
    ['A) Hindi','B) Bengali','C) Tamil','D) Telugu'],
    ['A) Facebook','B) WhatsApp','C) Instagram','D) Twitter'],
    ['A) Amazon','B) Flipkart','C) Myntra','D) Snapdeal'],
    ['A) Diwali','B) Holi','C) Dussehra','D) Onam'],
    ['A) Using relevant keywords in website content','B) Building backlinks from high-quality Indian websites','C) Optimizing website loading speed for slower internet connections','D) Targeting search queries in English only'],
    ['A) Bharat Broadband Nigam Limited (BBNL)','B) Digital India','C) Pradhan Mantri Gram Swarojgar Yojana (PMGSY)','D) Aadhaar'],
    ['A) Interactive video ads','B) Influencer marketing on Instagram','C) SMS marketing in local languages','D) Paid search advertising on Google'],
    ['A) Limited access to demographic data','B) Lack of skilled workforce in regional languages','C) Difficulty creating culturally relevant content','D) All of the above'],
    ['A) Blog posts','B) Webinars',
        	'C) Short-form video content (e.g., TikTok)',
        	'D) Podcasts'],
        ['A) High adoption of paid social advertising platforms','B) Strong network effects within communities',
        	'C) Focus on user-generated content and influencers',
        	'D) Widespread use of online forums and discussion boards'],
        ['	A) Raising awareness through online campaigns',
        	'B) Crowdfunding initiatives for community projects',
        	'C) Partnering with NGOs and government agencies',
        	'D) All of the above'
        ],
        ['A) Rising disposable income',
        'B) Increased internet penetration',
        'C) Convenience and wider product selection',
        'D) All of the above',
        ],
        ['A) Rising disposable income',
            'B) Increased internet penetration',
            'C) Convenience and wider product selection',
            'D) All of the above'
            ],
        ['A) Mumbai',
        'B) Bangalore',
        'C) Delhi',
        'D) Chennai',
        ],
        ['A) Offering competitive prices',
        'B) High-quality customer service',
        'C) Positive online reviews and testimonials',
        'D) All of the above'],
        ['A) Marathi',
            'B) Tamil',
            'C) Telugu',
            'D) Gujarati'
        ],
        ['A) Lack of awareness among users',
            'B) Limited regulatory framework',
            'C) Data breaches and misuse',
            'D) All of the above'
        ],
        ['A) Credit cards',
          'B) E-wallets',
          'C) UPI (Unified Payments Interface)',
          'D) Net banking'
            ],
        ['A) Lack of digital literacy',
          'B) Limited access to internet and devices',
          'C) Cultural resistance to online education',
          'D) All of the above',
            ],
        ['A) Facebook',
          'B) LinkedIn',
          'C) Instagram',
          'D) Twitter',
            ],
        ['A) Focus on micro-influencers with local reach',
          'B) Emphasize authenticity and cultural relevance',
          'C) Partner with regional and niche influencers',
          'D) All of the above'
            ],
        ['A) Interactive AR/VR experiences',
      'B) User-generated content campaigns',
      'C) Influencer-led live streaming events',
      'D) Hyperlocal and personalized marketing',
        ],
        ['A) Lack of reliable data analytics tools',
          'B) Attributing conversions across multiple channels',
          'C) Measuring offline impact of online campaigns',
          'D) All of the above',
            ],
        ['A) Create content in regional languages',
          'B) Partner with local language publishers',
          'C) Utilize regional language keywords in SEO',
          'D) All of the above'
            ],
        ['A) Ad blocker usage',
          'B) Increasing ad fatigue among consumers',
          'C) Fake news and misinformation',
          'D) All of the above',
            ],
        ['A) BharatNet',
          'B) Make in India',
          'C) Swachh Bharat Abhiyan',
          'D) National e-Governance Plan',
            ],
        ['A) Provide access to online marketplaces',
            'B) Offer affordable digital marketing tools',
            'C) Build local community engagement',
            'D) All of the above'
            ],
        ['A) Data privacy and security',
            'B) Cultural sensitivity and representation',
            'C) Avoiding discrimination and stereotypes',
            'D) All of the above',
            ],
        ['A) Understanding user intent and pain points',
            'B) Creating high-quality and informative content',
            'C) Consistent content publishing and distribution',
            'D) All of the above'
            ],
        ['A) Incorporate local myths and legends',
            'B) Focus on emotional connection and relatability',
            'C) Utilize traditional forms of storytelling',
            'D) All of the above',
            ],
        ['A) Artificial intelligence and machine learning',
            'B) Voice search and smart devices',
            'C) Augmented reality and virtual reality',
            'D) All of the above',
            ],
        ['A) Adaptability and continuous learning',
            'B) Strong understanding of local customs and trends',
            'C) Data-driven approach and experimentation',
            'D) All of the above'
            ],
        ['A) E-books',
            'B) Video lectures',
            'C) Interactive quizzes and simulations',
            'D) Live online workshops'
            ],
        ['A) Facebook',
            'B) Twitter',
            'C) YouTube',
            'D) WhatsApp'
            ],
        ['A) Lack of reliable infrastructure in rural areas',
            'B) Cash-on-delivery preference among consumers',
            'C) Difficulty managing last-mile delivery',
            'D) All of the above',
            ],
        ['A) Amazon',
            'B) Flipkart',
            'C) Myntra',
            'D) Etsy'
            ],
        ['A) Personalizing customer experiences',
            'B) Optimizing ad targeting and bidding',
            'C) Generating creative content and copy',
            'D) All of the above',

            ],
        ['A) Promoting agricultural products and services',
            'B) Providing access to financial literacy resources',
            'C) Raising awareness about healthcare initiatives',
            'D) All of the above'
            ],
        ['A) Hindi',
            'B) Tamil',
            'C) Telugu',
            'D) Marathi'
            ],
        ['A) Collaborations with regional and niche influencers',
            'B) Focus on long-term partnerships and brand advocacy',
            'C) Integration of influencer content into omnichannel campaigns',
            'D) All of the above'
            ],
        ['A) Create authentic and relatable content',
            'B) Utilize humor and meme culture',
            'C) Partner with Gen Z creators and influencers',
            'D) All of the above'
            ],
        ['A) High expectations for personalized experiences',
            'B) Preference for video and interactive content',
            'C) Skepticism towards traditional advertising methods',
            'D) All of the above'
            ],
        ['A) Offer multilingual support options',
            'B) Train customer service representatives in regional languages',
            'C) Utilize language translation tools',
            'D) All of the above'
            ],
        ['A) Increasingly stringent data protection regulations',
            'B) Rising consumer awareness about data usage',
            'C) Prevalence of data breaches and misuse',
            'D) All of the above'
            ],
        ['A) Creating interactive product experiences',
            'B) Offering virtual try-on and visualization tools',
            'C) Hosting AR-powered scavenger hunts and games',
            'D) All of the above'
            ],
        ['A) Creating interactive product experiences',
            'B) Offering virtual try-on and visualization tools',
            'C) Hosting AR-powered scavenger hunts and games',
            'D) All of the above'
            ],
        ['A) Creating inclusive and representative content',
            'B) Partnering with LGBTQ+ influencers and organizations',
            'C) Supporting Pride events and initiatives',
            'D) All of the above'
            ],
        ['A) Increased adoption of hyperlocal dating apps',
            'B) Focus on community-based and interest-driven matches',
            'C) Integration of AI and personality profiling for recommendations',
            'D) All of the above'
            ],
        ['A) Raising awareness about environmental issues',
            'B) Highlighting eco-friendly products and services',
            'C) Encouraging community-based sustainability initiatives',
            'D) All of the above'
            ],
        ['A) Lack of digital infrastructure and devices in rural areas',
            'B) Limited internet connectivity and bandwidth',
            'C) Affordability of online learning courses',
            'D) All of the above'
            ],
        ['A) Highlight local cuisines and culinary heritage',
            'B) Partner with renowned chefs and food bloggers',
            'C) Create recipe videos and cooking tutorials',
            'D) All of the above'
            ],
         ['A) Building trust with users about sensitive medical information',
              'B) Ensuring compliance with healthcare data protection regulations',
              'C) Implementing robust security measures against cyber threats',
              'D) All of the above'
            ],
         ['A) Breaking down stigmas and encouraging open conversations',
              'B) Connecting individuals with mental health resources and support',
              'C) Partnering with NGOs and mental health professionals',
              'D) All of the above'            ],
         ['A) Personalized content recommendations based on user preferences',
              'B) Short-form video platforms with interactive features',
              'C) User-generated content and community-driven conversations',
              'D) All of the above',
            ],
         ['A) Utilizing multi-channel attribution models',
              'B) Setting clear goals and tracking relevant metrics',
              'C) Conducting A/B testing and experimenting with different strategies',
              'D) All of the above'
            ],
         ['A) Marathi',
              'B) Tamil',
              'C) Telugu',
              'D) Punjabi'
            ],
         ['A) Using recycled materials and eco-friendly packaging',
              'B) Partnering with ethical brands and social enterprises',
              'C) Promoting transparency and responsible advertising practices',
              'D) All of the above'
            ],
         ['A) Maintaining authenticity and avoiding over-promotion',
              'B) Ensuring transparency and disclosure of sponsored content',
              'C) Dealing with negative comments and online backlash',
              'D) All of the above'
            ],
         ['A) Providing access to online marketplaces and marketing resources',
              'B) Building communities and networks for support and mentorship',
              'C) Highlighting success stories and breaking gender stereotypes',
              'D) All of the above'
            ],
         ['A) Increasingly stringent data protection regulations',
              'B) Lack of awareness among consumers about data usage',
              'C) Rise of data breaches and cyberattacks',
              'D) All of the above'
            ],
         ['A) Hosting immersive product demonstrations and virtual tours',
              'B) Creating VR-based storytelling experiences and brand activations',
              'C) Partnering with VR gaming platforms and communities',
              'D) All of the above'
            ],
        ['A) Bit',
            'B) Byte',
            'C) Packet',
            'D) Frame Answer'
            ],
        ['A) Application layer',
            'B) Network layer',
            'C) Data link layer',
            'D) Physical layer '],
        ['A) TCP is reliable, UDP is unreliable',
            'B) TCP is packet-oriented, UDP is stream-oriented',
            'C) TCP is faster, UDP is slower',
            'D) TCP is for text data, UDP is for multimedia data '],
        ['A) Twisted-pair cable',
            'B) Coaxial cable',
            'C) Fiber optic cable',
            'D) Wireless channel' ],
        ['A) HTTP',
            'B) FTP',
            'C) TCP',
            'D) IP ',
            ],
        ['A) To create separate virtual networks within a physical network',
            'B) To control the flow of data between networks and prevent unauthorized access',
            'C) To encrypt data transmissions',
            'D) To connect different types of networks together '],
        ['A) LANs are slower than WANs',
            'B) LANs are larger than WANs',
            'C) LANs are private networks, WANs are public networks',
            'D) LANs use different protocols than WANs '],
        ['A) Wi-Fi offers higher bandwidth and longer range',
            'B) Wi-Fi is more secure than Bluetooth',
            'C) Wi-Fi is easier to set up than Bluetooth',
            'D) Wi-Fi uses less power than Bluetooth '],
        ['A) To identify a specific device on a network',
            'B) To route data packets across the internet',
            'C) To encrypt data transmissions',
            'D) To control the flow of data between networks '],
        ['A) Analog signals are continuous, digital signals are discrete',
            'B) Analog signals are faster, digital signals are slower',
            'C) Analog signals are more secure, digital signals are less secure',
            'D) Analog signals are used for text data, digital signals are used for multimedia data'],
        ['A) To amplify the signal strength in a network',
            'B) To connect different types of networks together',
            'C) To control the flow of data between networks',
            'D) To route data packets across the internet '],
        ['A) Switches provide dedicated bandwidth to each device connected',
            'B) Switches are faster than hubs',
            'C) Switches are more secure than hubs',
            'D) Switches are easier to set up than hubs' ],
        ['A) Twisted-pair cable',
            'B) Coaxial cable',
            'C) Fiber optic cable',
            'D) Wireless channel '],
        ['A) Modulation',
            'B) Encryption',
            'C) Segmentation',
            'D) Compression ',],
        ['A) Symmetric offers faster upload speeds, asymmetric offers faster download speeds',
            'B) Symmetric offers dedicated bandwidth, asymmetric shares bandwidth with other users',
            'C) Symmetric is used for home networks, asymmetric is used for businesses',
            'D) There is no practical difference in usage for most users',],
        ['A) To encrypt data transmissions',
            'B) To compress data for faster transmission',
            'C) To identify and fix errors in transmitted data',
            'D) To prioritize certain types of data over others ',],
        ['A) HTTP',
            'B) FTP',
            'C) TCP',
            'D) IP ',
            ],
        ['A) Circuit switching establishes a dedicated path for data, packet switching shares resources dynamically',
            'B) Circuit switching is faster, packet switching is slower',
            'C) Circuit switching is more secure, packet switching is less secure',
            'D) Circuit switching is used for voice calls, packet switching is used for data '],
        ['A) To connect geographically dispersed LANs into a single logical network',
            'B) To segment a single LAN into smaller logical networks for security and traffic management',
            'C) To provide wireless connectivity to devices on a wired network',
            'D) To extend the range of a network beyond its physical limitations ',],
        ['A) Bit',
            'B) Byte',
            'C) Hertz',
            'D) Mbps '],
        ['A) To combine multiple signals into a single signal',
            'B) To separate a single signal into multiple signals',
            'C) To amplify the signal strength in a network',
            'D) To control the flow of data between networks',],
        ['A) Synchronous transmission has fixed timing, asynchronous is flexible',
            'B) Synchronous transmission is faster, asynchronous is slower',
            'C) Synchronous transmission is used for text data, asynchronous is used for multimedia data',
            'D) There is no practical difference in usage for most users',
        ],

        ['A) Wireless networks are slower and more susceptible to interference',
            'B) Wireless networks are more expensive to set up and maintain',
            'C) Wireless networks require specialized equipment not needed for wired networks',
            'D) Wireless networks are less secure and more vulnerable to hacking',
        ],
        ['A) Byte',
            'B) Word',
            'C) Instruction',
            'D) Register',
            ],
        ['A) Byte',
            'B) Word',
            'C) Instruction',
            'D) Register',
            ],
        ['A) Three-address',
            'B) Two-address',
            'C) One-address',
            'D) Zero-address',
            ],
        ['A) To store temporary data during calculations',
            'B) To track the next instruction to be fetched and executed',
            'C) To manage memory allocation and data access',
            'D) To control the flow of data between the CPU and peripherals',
            ],
        ['A) To increase the speed of program execution',
            'B) To provide an illusion of a larger physical memory than available',
            'C) To improve data security and prevent unauthorized access',
            'D) To simplify memory management for programmers',
            ],
        ['A) Synchronous uses a clock signal for precise data transfers, asynchronous relies on handshaking signals',
            'B) Synchronous is faster than asynchronous, asynchronous is slower',
            'C) Synchronous is more complex to design than asynchronous',
            'D) Synchronous is used for main memory, asynchronous for cache memory',
            ],
        ['A) To perform arithmetic and logical operations',
            'B) To control communication between the CPU and memory',
            'C) To decode instructions and manage program execution',
            'D) To handle input and output operations with peripherals',
            ],
        ['A) Fetch',
            'B) Decode',
            'C) Execute',
            'D) Write back',
            ],
        ['A) To improve accuracy of arithmetic calculations',
            'B) To predict the direction of conditional branch instructions for faster execution',
            'C) To manage data transfers between different memory levels',
            'D) To handle interrupts and priority scheduling',
            ],
        ['A) Increased clock speed and raw processing power',
            'B) Improved performance for parallel tasks and workloads',
            'C) Reduced power consumption and smaller chip size',
            'D) Simplified code development and debugging',
            ],
        ['A) Control unit',
            'B) Arithmetic logic unit (ALU)',
            'C) Bus',
            'D) Cache',
            ],
        ['A) By executing multiple instructions simultaneously within a single core',
            'B) By increasing the clock speed of the processor',
            'C) By expanding the size of the cache memory',
            'D) By utilizing specialized instructions for specific tasks',
            ],
        ['A) Speculative execution pre-executes instructions based on predicted branch paths, while branch prediction only suggests the likely direction.',
            'B) Branch prediction is more accurate than speculative execution.',
            'C) Speculative execution has higher performance implications if the prediction is wrong.',
            'D) Both techniques are equally effective and interchangeable.',
            ],
        ['A) Paging',
            'B) Segmentation',
            'C) Virtual memory',
            'D) Interleaving',
            ],
        ['A) Improved performance for handling large arrays and mathematical operations',
            'B) Reduced power consumption and smaller chip size',
            'C) Increased clock speed and raw processing power',
            'D) Simplified code development and debugging',
            ],
        ['A) To store frequently accessed virtual-to-physical address translations for faster access',
            'B) To manage data transfers between different memory levels',
            'C) To handle interrupts and priority scheduling',
            'D) To perform error detection and correction on memory data',
            ],
        ['A) L1 cache is smaller and faster than L2 cache, located closer to the CPU.',
            'B) L2 cache is larger and faster than L1 cache, located further away from the CPU.',
            'C) L1 cache stores instructions, while L2 cache stores data.',
            'D) L1 cache is only implemented in high-end processors, while L2 cache is standard for most CPUs.',
            ],
    ]