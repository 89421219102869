import Modal from '@mui/material/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function NumPyMod() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} style={{color:'teal'}}>introduction to NumPy</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style} height={300} width={300} style={{'background':'aquamarine'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           NumPy
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Numpy is a library which is used for working with large set of data<br/>


            1:-It works on list, set, dictionary and strings or a collection of data:- Ctr+N<br/>
            2:-import numpyt is written to import numpy library  <br/>
            3:-We use numpy.array() method is used to create an array.<br/>
            4:-It can create both 1D and multi dimensional arrays<br/>
            5:-We use index to access array of numpy<br/>
           






          </Typography>
        </Box>
      </Modal>
    </div>
  );
}