export function RankMod()
{
    return(
        <div><marquee>
            <img src="b1.jpg" alt="qAi" width="35%" height="180"/>
            <img src="b2.jpg" alt="qAi" width="30%" height="180"/>
            <img src="b3.jpg" alt="qAi" width="35%" height="180"/>
            </marquee>
        </div>
    );
}